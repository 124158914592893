import Tabs from "../../common/tabs/tabs.component";
import GridContainer from "../../common/grid-container/grid-container.component";
import GridCol from "../../common/grid-col/grid-col.component";
import CopyText from "../../common/copy-text/copy-text.component";
import CopyTextWithoutIcon from "../../common/copy-text/copy-text-without-icon.component";
import tt from "../../../helpers/translate.helper";
import PhotoGalleryCarousel from "../../common/photo-gallery/photo-gallery-carousel.component";
import { imagesHelp } from "../../../images/images";

const HelpSection = () => (
  <section className="project-section">
    <div className="container">
      <h3 className="project-title" data-tr>
        {tt("Братерська допомога")}
      </h3>
      <div className="project-intro">
        {tt("Сьогодні багато чого є в наших хлопців на передовій...")}
      </div>
      <h4 className="fundraising-title" id="closest" data-tr>
        {tt("Світлини")}
      </h4>
      <PhotoGalleryCarousel items={imagesHelp} />
    </div>
    <div className="payment-details" id="donate">
      <div className="container">
        <h4 className="payment-details-title" data-tr>
          {tt("Реквізити")} - {tt("Братерська допомога")}
        </h4>

        <Tabs>
          {[
            [
              tt("Банківський переказ"),
              <section>
                <GridContainer>
                  <GridCol cols={6} className="qr-code-col">
                    <section className="qr-code-wrapper">
                      <a
                        href="https://www.privat24.ua/rd/transfer_to_card/?hash=rd%2Ftransfer_to_card%2F%7B%22from%22%3A%22%22%2C%22to%22%3A%224731219630534950%22%2C%22amt%22%3A%220%22%2C%22ccy%22%3A%22UAH%22%7D"
                        className="qr-code-title"
                      >
                        {tt("Приватбанк")}
                      </a>
                      <div className="qr-code-image-wrapper">
                        <a href="https://www.privat24.ua/rd/transfer_to_card/?hash=rd%2Ftransfer_to_card%2F%7B%22from%22%3A%22%22%2C%22to%22%3A%224731219630534950%22%2C%22amt%22%3A%220%22%2C%22ccy%22%3A%22UAH%22%7D">
                          <img
                            className="qr-code"
                            src="/images/qr-codes/qr-code-dopomoga-privatbank.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <p className="qr-card-name">
                        <CopyTextWithoutIcon>
                          4731 2196 3053 4950
                        </CopyTextWithoutIcon>
                      </p>
                    </section>
                  </GridCol>
                  <GridCol cols={6} className="qr-code-col">
                    <section className="qr-code-wrapper">
                      <a
                        href="https://send.monobank.ua/jar/78HnaU2A4p"
                        className="qr-code-title"
                      >
                        {tt("Монобанк")}
                      </a>
                      <div className="qr-code-image-wrapper">
                        <a href="https://send.monobank.ua/jar/78HnaU2A4p">
                          <img
                            className="qr-code"
                            src="/images/qr-codes/qr-code-dopomoga-monobank.png"
                            alt=""
                          />
                        </a>
                      </div>
                      <p className="qr-card-name">
                        <CopyTextWithoutIcon>
                          5375 4112 0033 1432
                        </CopyTextWithoutIcon>
                      </p>
                    </section>
                  </GridCol>
                </GridContainer>
              </section>,
            ],
            [
              "PayPal / Wise",
              <section className="payment-btn-section">
                <GridContainer>
                  <GridCol>
                    <div className="payment-btn">
                      <a
                        className="payment-btn-icon"
                        href="https://www.paypal.com/myaccount/transfer/homepage"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="images/paypal-logo.svg" alt="" />
                        <div className="payment-btn-title">Paypal</div>
                      </a>
                      <div className="payment-btn-email">
                        <CopyText>antondovgobrod@gmail.com</CopyText>
                      </div>
                    </div>
                  </GridCol>
                  <GridCol>
                    <div className="payment-btn">
                      <a
                        className="payment-btn-icon icon-wise"
                        href="https://wise.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="images/wise-logo.svg" alt="" />
                      </a>
                      <div className="payment-btn-email">
                        <CopyText>antondovgobrod@gmail.com</CopyText>
                      </div>
                    </div>
                  </GridCol>
                </GridContainer>
              </section>,
            ],
            [
              "Crypto",
              <section>
                <h6 className="payment-details-currency-title">Monero XMR:</h6>
                <p className="payment-details-currency-vallet">
                  <CopyText>
                    4AroqwWpUFXNbMqk6AeWe2YUBd9c16kg44b5EPE6M959V749i8J5G9dgtJDPDgGpgtcsGAzUs8ehJQbnKVbJeWp1QFnh83n
                  </CopyText>
                </p>
                <h6 className="payment-details-currency-title">
                  TRON (trc20) TRX/USDD/USDT/USDC:
                </h6>
                <p className="payment-details-currency-vallet">
                  <CopyText>TCzzSmotAEojZwRcVJJXCbxUr1HmYD32XJ</CopyText>
                </p>
                {/* <h6 className="payment-details-currency-title">
                  Ethereum ETH/BNB/USDC/USDT:
                </h6>
                <p className="payment-details-currency-vallet">
                  <CopyText>
                    0x465B1C4D3dB1A561cD44b77980e3d52f742f0E4c
                  </CopyText>
                </p> */}
              </section>,
            ],
            // [
            //   "World",
            //   <section>
            //     <GridContainer>
            //       <GridCol>
            //         <section className="payment-section">
            //           <h5 className="payment-details-subtitle">US</h5>
            //           <h6 className="payment-details-currency-title">
            //             Reference
            //           </h6>
            //           <p className="payment-details-currency-vallet">
            //             <CopyText>852892</CopyText>
            //           </p>
            //           <h6 className="payment-details-currency-title">
            //             Account holder
            //           </h6>
            //           <p className="payment-details-currency-vallet">
            //             <CopyText>Anton Dovhobrod</CopyText>
            //           </p>
            //           <h6 className="payment-details-currency-title">
            //             Routing number
            //           </h6>
            //           <p className="payment-details-currency-vallet">
            //             <CopyText>084009519</CopyText>
            //           </p>
            //           <h6 className="payment-details-currency-title">
            //             Account number
            //           </h6>
            //           <p className="payment-details-currency-vallet">
            //             <CopyText>9600005409020305</CopyText>
            //           </p>
            //           <h6 className="payment-details-currency-title">
            //             Account type
            //           </h6>
            //           <p className="payment-details-currency-vallet">
            //             <CopyText>Checking</CopyText>
            //           </p>
            //           <h6 className="payment-details-currency-title">
            //             Address
            //           </h6>
            //           <p className="payment-details-currency-vallet">
            //             <CopyText>
            //               30 W. 26th Street, Sixth Floor New York NY 10010
            //               United States
            //             </CopyText>
            //           </p>
            //         </section>
            //       </GridCol>
            //       <GridCol>
            //         <section className="payment-section">
            //           <h5 className="payment-details-subtitle">EU</h5>
            //           <h6 className="payment-details-currency-title">
            //             Reference
            //           </h6>
            //           <p className="payment-details-currency-vallet">
            //             <CopyText>862076</CopyText>
            //           </p>
            //           <h6 className="payment-details-currency-title">
            //             Account holder
            //           </h6>
            //           <p className="payment-details-currency-vallet">
            //             <CopyText>Anton Dovhobrod</CopyText>
            //           </p>
            //           <h6 className="payment-details-currency-title">BIC</h6>
            //           <p className="payment-details-currency-vallet">
            //             <CopyText>TRWIBEB1XXX</CopyText>
            //           </p>
            //           <h6 className="payment-details-currency-title">IBAN</h6>
            //           <p className="payment-details-currency-vallet">
            //             <CopyText>BE50 9673 3663 4018</CopyText>
            //           </p>
            //           <h6 className="payment-details-currency-title">
            //             Address
            //           </h6>
            //           <p className="payment-details-currency-vallet">
            //             <CopyText>
            //               Avenue Louise 54, Room S52 Brussels 1050 Belgium
            //             </CopyText>
            //           </p>
            //         </section>
            //       </GridCol>
            //     </GridContainer>
            //   </section>,
            // ],
          ]}
        </Tabs>
      </div>
    </div>
  </section>
);

export default HelpSection;
