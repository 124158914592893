export const imagesHelp = [
  {
    original: "photos/help/01-005.jpg",
    thumbnail: "photos/help/01-005.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/01-003.PNG",
    thumbnail: "photos/help/01-003.PNG",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/01-101.jpg",
    thumbnail: "photos/help/01-101.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/01-102.jpg",
    thumbnail: "photos/help/01-102.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/01-002.jpg",
    thumbnail: "photos/help/01-002.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/01-001.jpg",
    thumbnail: "photos/help/01-001.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/02.jpg",
    thumbnail: "photos/help/02.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/03.jpg",
    thumbnail: "photos/help/03.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/04.jpg",
    thumbnail: "photos/help/04.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/05.jpg",
    thumbnail: "photos/help/05.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/06.jpg",
    thumbnail: "photos/help/06.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/07.jpg",
    thumbnail: "photos/help/07.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/08.jpg",
    thumbnail: "photos/help/08.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/09.jpg",
    thumbnail: "photos/help/09.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/10.jpg",
    thumbnail: "photos/help/10.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/11.jpg",
    thumbnail: "photos/help/11.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
];

